<template>
  <div
      v-loading="loading"
      :element-loading-text="text"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.1)"
      style="width:100px; left:42%; top:46%; position: absolute"></div>
</template>

<script>
export default {
  name: "Loading",
  props:['loading','text'],
}
</script>

<style scoped>

</style>
