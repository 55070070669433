<template>
  <el-pagination
      background
      layout="total,prev, pager, next, jumper,sizes"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      style="text-align: center;padding: 30px 0px 40px 0px;"
      v-show="total>0"
      :page-sizes="[20, 30]"
      :page-size="size"
      :current-page="page"
  >
  </el-pagination>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  name: "Pagination",
  props:['total','page','size'],
  methods:{
    handleCurrentChange(val,e,form,jobExpLabel,pageform,searchVal) {
      this.$emit('pageChange',val);
    },
    handleSizeChange(val) {
      this.$emit('sizeChange',val);
    },
  }
}
</script>

<style scoped>

</style>
